<template>
  <div class="ps-page--single">
    <div class="ps-vendor-banner bg--cover" data-background="/img/bg/vendor.jpg" style="background: url('~/img/bg/vendor.jpg')">
      <div class="container">
        <h2>Millions Of Shoppers Can’t Wait To See What You Have In Store</h2>
        <a class="ps-btn ps-btn--lg" href="#">Start Selling</a>
      </div>
    </div>
    <div class="ps-section--vendor">
        <div class="ps-section__header">
          <h4>Want to become a Pahukama e-marketplace merchant, Apply For a shop .</h4>
      </div>
    </div>

    <div class="ps-section__content container mt-4">
      <form class="ps-form--checkout" method="get">
        <div class="ps-form__content">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="ps-form__billing-info">
                <h3 class="ps-form__heading">Please Add Shop Information</h3>
                <div class="form-group">
                  <label>Shop name</label>
                  <input v-model="shop.name" class="form-control" type="text" placeholder />
                  <small v-if="errors && errors.name" class="text-danger">
                    {{ errors.name[0] }}
                  </small>
                </div>
                <div class="form-group">
                  <label>Select Shop Category</label>
                  <select class="form-control" aria-label="Default select example" v-model="shop.shop_category_id">
                    <option
                      v-for="shopCategory in shopCategories"
                      :key="shopCategory.id"
                      :value="shopCategory.id"
                    >{{ shopCategory.name }}</option>
                  </select>
                  <small v-if="errors && errors.shop_category" class="text-danger">
                    {{ errors.shop_category[0] }}
                  </small>
                </div>

                <h3 class="ps-form__heading">Shop Address</h3>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>City</label>
                      <input v-model="shop.city" class="form-control" type="text" placeholder />
                    </div>
                    <small v-if="errors && errors.city" class="text-danger">
                      {{ errors.city[0] }}
                    </small>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Country</label>
                      <input v-model="shop.country" class="form-control" type="text" placeholder />
                    </div>
                    <small v-if="errors && errors.country" class="text-danger">
                      {{ errors.country[0] }}
                    </small>
                  </div>
                </div>
                <div class="form-group">
                  <label>Street</label>
                  <textarea class="form-control" name id rows="4" v-model="shop.street"></textarea>
                  <small v-if="errors && errors.street" class="text-danger">
                    {{ errors.street[0] }}
                  </small>
                </div>
                <div class="form-group" v-if="!loading">
                  <label>Pick Shop Location</label>
                  <!-- <place-form></place-form> -->
                  <GmapAutocomplete
                    class="form-control"
                    v-if="!loadingForm"
                    @place_changed="setPlace"
                  />
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input v-model="shop.phone_number" class="form-control" type="text" />
                      <small v-if="errors && errors.phone_number" class="text-danger">
                        {{ errors.phone_number[0] }}
                      </small>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Shop Image</label>
                      <input class="form-control" type="file" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Shop Description</label>
                  <textarea name class="form-control" id rows="4" v-model="shop.description"></textarea>
                  <small v-if="errors && errors.description" class="text-danger">
                    {{ errors.description[0] }}
                  </small>
                </div>

                <div class="form-group">
                  <button class="ps-btn ps-btn--fullwidth" @click.prevent="onSubmit()">
                    {{ loading ? "Processing ..." : "Apply" }}
                    <div v-if="loading" class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import GoogleMap from "@/components/GoogleMap.vue";
import global from "@/mixins/global.js";
import axios from "axios";
import PlaceForm from "../../components/PlaceForm.vue";
export default {
  components: { GoogleMap, PlaceForm },
  data() {
    return {
      shop: {
        name: "",
        city: "",
        street: "",
        description: "",
        country: "",
        phone_number: "",
        shop_category_id: "",
        image: "",
      },
      loading: false,
      currentPlace: {},
      loadingForm: true,
      errors: null,
      message: null,
      isError: false
    };
  },
  mixins: [global],
  mounted() {
    this.getShopCategories();
    setTimeout(() => {
      this.loadingForm = false;
    }, 5000);
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    onSubmit() {
      this.loading = true;
      axios.post('shops/create',{
            name: this.shop.name,
            city: this.shop.city,
            street: this.shop.street,
            description: this.shop.description,
            country: this.shop.country,
            phone_number: this.shop.phone_number,
            shop_category_id: this.shop.shop_category_id,
            current_place: this.currentPlace
          }
        ).then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.$router.push({name: 'waiting_shop'});
          }
          this.loading = false;
        }).catch((error) => {
          if (error.response.status === 422) {
            this.message = "Shop could not be created. Please enter all valid required fields.";
            this.errors = error.response.data.errors
          } else {
            this.message = error.backendErrors[0]
          }
          this.isError = true
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.ps-section--vendor .ps-section__header {
  text-align: center;
  padding-bottom: 10px !important;
}
.ps-section--vendor {
  padding: 10px 0 !important;
}
.ps-vendor-banner h2 {
  color: #000000;
  font-size: 48px;
  margin: 0 auto 10px;
  max-width: 960px;
  font-weight: 600;
}
.ps-vendor-banner {
  position: relative;
  text-align: center;
  z-index: 10;
  padding: 60px 0;
}
</style>